.location-trigger {
  // max-width: 300px;
  // min-width: 200px;
  border: 1px solid #dadce0;
  height: 48px;
  border-radius: 4px;
  background: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 20px 0 12px;
  width: 100%;

  @media only screen and (min-width: 1100px) {
    min-width: 280px;
  }

  .grayed-placeholder {
    color: #9d9d9d;
  }
}

.location-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 46px;
  border: none !important;
  border-radius: 4px 4px 0 0;
}

.pindrop-icon {
  padding-left: 12px;
}

.passengers-container {
  width: 120px;
  border: 1px solid #dadce0;
  height: 48px;
  border-radius: 4px;
  background: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 20px 0 12px;
  justify-content: space-between;
}

.passengers-popover {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
  // width: 100%;
  width: 240px;
  border-radius: 4px;
  box-shadow: 4px 4px 10px #00000029;
  padding: 32px 24px;

  .passenger-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    column-gap: 16px;

    .count-buttons {
      width: 24px;
      height: 24px;
      background: #f1f7fe;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      border-radius: 4px;
    }

    .custom-select {
      position: relative;
      select {
        appearance: none;
        -webkit-appearance: none;
        width: 50px;
        // font-size: 1.15rem;
        padding: 4px 10px;
        background-color: #fff;
        border: 1px solid #caced1;
        border-radius: 4px;
        color: #000;
        cursor: pointer;
      }
    }

    .custom-select::before,
    .custom-select::after {
      size: 4px;
      position: absolute;
      content: "";
      right: 10px;
      pointer-events: none;
    }
  }
}
