.home-wrapper {
  overflow-y: auto;
  height: calc(100vh - 64px);
  background: #f8f8f8;

  .home-container {
    max-width: 1000px;
    margin: 60px auto;
  }

  .home-product-details-section {
    border: 1px solid #ececf9;
    border-radius: 8px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    .home-helper-card-left {
      width: 60%;

      .home-video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 160px;
        border: 1px solid #3e0e3f;
        border-radius: 8px;
        transition: all 0.3 ease-in-out;
        cursor: pointer;

        .video-play-button {
          background: #724a74;
          padding: 14px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .home-helper-card-right {
      width: 40%;
      border-left: 1px solid #ececf9;

      .home-links {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          color: #408dfb;
        }
      }
    }

    .home-product-details-header {
      background: #893297;
      color: #fff;
      border-radius: 8px 8px 0 0;
      padding: 1rem 2rem;

      h6 {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .home-product-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      border: 1px solid #ececf9;
      border-radius: 8px;
      padding: 1.4rem 2rem;
      background: #fff;

      .home-card-icon-wrapper {
        background: #f9eeff;
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .home-card-button {
        background: #f8f8f8;
        color: #3e0e3f;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #5a2d5c;
          color: #fff;
        }
      }
    }
  }
}
