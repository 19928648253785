.color_success {
  color: #22b378 !important;
}
.color_danger {
  color: #d72e38 !important;
}
.color_warning {
  color: #f27914 !important;
}
.color_primary {
  color: #1966a1 !important;
}
.color_primary_main {
  color: #276ef1 !important;
}
.color_violation {
  color: #ff8a00 !important;
}
.color_violation_desc {
  color: #b15620 !important;
}

.color_black {
  color: #3c4043 !important;
}

.text_color {
  color: #696d9a !important;
}

.trips_bg {
  background-color: #f8f8fd !important;
}
.bg-body {
  background-color: #f7f7fe !important;
}
.bg-white {
  background-color: #fff !important;
}
