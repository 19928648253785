@import "@scss/common/variables";

.not-found-wrapper {
  width: 100%;
  // height: calc(100vh - 165px);
  height: calc(100vh - 233px);
  // background-color: $white-color;
  cursor: pointer;
}

.not-found-image {
  height: 250px;
  width: 250px;
  text-align: center;
  border: 1px dashed $theme-border-color;
  border-radius: 4px;
  transition: 0.3ms all ease-in-out;
  &:hover {
    border-color: rgba(var(--primary-color), 1);
  }
}

.not-found-wrapper-small {
  width: 100%;
  // height: calc(100vh - 165px);
  // height: calc(100vh - 233px);
  height: 50vh;
  background-color: $white-color;
  cursor: pointer;
}

.not-found-wrapper__details-small {
  margin: auto;
  p.no-data-found {
    margin: 20px 0;
    color: $theme-font-primary-color;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
}
.not-found-image-small {
  height: 200px;
  width: 200px;
  text-align: center;
  border: 1px dashed $theme-border-color;
  border-radius: 4px;
  transition: 0.3ms all ease-in-out;
  &:hover {
    border-color: rgba(var(--primary-color), 1);
  }
}

.new-not-found-wrapper {
  .not-found-wrapper__details {
    .not-found-image {
      &.trips-image {
        height: auto;
        width: 264px;
        border: 0;
      }
    }

    .not-found-text {
      margin: 16px auto;
      font-size: 16px;
      line-height: 24px;
      color: $theme-font-heading-color;

      &.setting_traveldoc_notfound_text {
        max-width: 608px;
      }
    }

    .btn-add-on_not_found {
      font-size: 14px;
      line-height: 18px;
      height: 40px;
      border-radius: 4px;
      background-color: #276ef1;
      font-weight: $medium-font-weight;
      padding: 11px 16px;

      svg {
        margin-right: 8px;
      }
    }
  }
}
