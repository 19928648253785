@import "@scss/common/variables";

.add-filter-wrapper {
  // background-color: white !important;
  // padding: 8px 24px;
  // border-bottom: 1px solid $theme-border-color;
  z-index: 9999;

  height: 54px;

  &--border {
    border-top: 1px solid #f7f7f7;
  }
  .add-filter-wrapper__add-icon {
    background-color: #893297;
    // background-color: #fd9933;
    height: 40px;
    width: fit-content;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 4px;
    color: #fff;
    line-height: normal;
    transition: all 0.3s ease-in-out;
    svg {
      margin: auto;
      text-align: center;
    }

    &:hover {
      background: #5a2d5c;
    }
  }
  .add-filter-wrapper__filter-status {
    font-weight: 300;
    color: #276ef1;
    font-size: 14px;
  }
  .add-filter-wrapper__add-filter-title {
    font-weight: 400;
    color: #276ef1;
    margin-left: 8px;
    font-size: 14px;
  }
  .add-filter-wrapper__add-filter-title span {
    font-weight: 400;
    color: #5f6368;
    margin-left: 16px;
  }
  .add-filter-wrapper__filter {
    .add-filter-wrapper__filter-form-group {
      position: relative;
      .add-filter-wrapper__filter-input-image {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
      }
      .add-filter-wrapper__filter-image-close {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }
    .add-filter-wrapper__filter-form-group
      input.add-filter-wrapper__filter-search-input {
      border: 1px solid $theme-border-color;
      padding: 0 16px 0 28px;
      border-radius: 4px;
      height: 40px;
      line-height: 40px;
      min-width: 250px;
      width: 100%;
      font-size: 14px;
    }
  }
}

#addTooltip {
  z-index: 9;
}

#switchTooltip {
  z-index: 1000;
}
