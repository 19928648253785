:root {
  --primary-color: 215, 36, 46;
  --secondary-color: 0, 91, 166;
  --button-primary: 137, 50, 151;
  // --button-primary: 62, 14, 63;
}

@import "normalize.scss";
@import "extends.scss";
@import "common.scss";
@import "./colors.scss";
@import "./scrollbar.scss";
@import "./datatable.scss";
// added by sethu
.calender_text {
  color: rgba(var(--primary-color), 1);
}

.theme-primary-color {
  color: $theme-primary-color;
}

.theme-font-secondary-color {
  // color: $theme-secondary-color;
  color: rgba(var(--primary-color), 1);
}

.flat-button {
  background-color: transparent;
  border: none;

  &--no-padding {
    padding: 0;
  }
}

.header_text__32 {
  font-weight: 500;
  font-size: 32px;
  color: #212024;
}
.header_text__20 {
  font-weight: 500;
  font-size: 20px;
  color: #212024;
}

.deeptravel-button {
  height: 56px;
  line-height: 56px;
  margin: 4px 0;
  border-radius: 8px;
  border: none;
  background-color: #ffffff;
  min-width: 165px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;

  &--header-buttons {
    height: 40px;
    line-height: 40px;
    min-width: 130px;
  }
  &--visibility {
    opacity: 0;
    visibility: hidden;
  }
  &--primary {
    color: #fff;
    background-color: #893297;
    margin-right: 8px;
    border: 1px solid $theme-border-color;
    padding: 0 40px;

    &:hover {
      background: #5a2d5c;
    }
  }

  &--secondary {
    // background-color: rgba(var(--button-primary), 1);
    color: #212024;
    padding: 0 24px;
    border: 1px solid #dadce0;

    &:hover {
      // background: #5a2d5c;
    }
  }
  &--red {
    // border: 1px solid $theme-border-color;
    // background-color: white;
    // color: #dd4750;
    // border: 0.5px solid #dd4750;
    border: 1px solid#dadce0;
    color: #dd4750;
    font-weight: 800;
    font-size: 16px;
    padding: 0 24px;
    // &:hover{
    //   @extend %transition;
    //   background-color: #dd4750;
    //   color:  #fff;
    // }
  }
  &--orange {
    border: 1px solid $theme-border-color;
    background-color: white;
    color: #fdb367;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 500;
  }
  &--green {
    // background-color: #05a357;
    // border: 0.5px solid #28B463;
    border: 1px solid#dadce0;
    color: #28b463;
    // color: white;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 800;
    // &:hover{
    //   @extend %transition;
    //   background-color: #28B463;
    //   color:  #fff;
    // }
  }

  &:disabled {
    background-color: #f8f8f8;
    color: #d5d5d5 !important;
    border: 1px solid #dadce0;
  }
}

.bg-disabled {
  background-color: #f8f8f8;
  color: #aeaeae !important;
  cursor: not-allowed;
  p {
    color: #d5d5d5 !important;
  }
  svg {
    fill: #fff !important;
  }
}

.theme-button {
  padding: 15px 24px;
  border-radius: $base-border-radius;
  border: 1px solid $theme-border-color;
  @extend %text-capitalize;

  &.theme-primary-btn {
    background-color: $theme-primary-color;
    color: $white-color;
  }

  &.theme-secondary-btn {
    background-color: #ffff;
    // color: $theme-link-secondary-color;
  }

  &.theme-white-btn {
    background-color: #ffffff;
    //  color: $theme-primary-light-color;
  }

  &.theme-white-secondary-button {
    background-color: $white-color;
    color: $theme-primary-color;
  }
}

.initial-line-height {
  line-height: 1;
}

.margin-auto {
  margin: auto;
}

.custom-container {
  max-width: 1095px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: $mobile-devices-max-width+px) {
    padding: 0 16px;
  }
}

.white-color {
  @extend %white-color;
}

.theme-secondary-text-color {
  @extend %theme-secondary-text-color;
}

.theme-secondary-color {
  // color: $theme-secondary-color;
  color: rgba(var(--primary-color), 1);
}

.normal-font {
  @extend %normal-font;
}

.light-font {
  @extend %light-font;
}

.bold-font {
  @extend %bold-font;
}

.medium-bold-font {
  @extend %medium-bold-font;
}

.flex-wrap {
  flex-wrap: wrap;
}

.arrow {
  &.swiper-button-disabled {
    opacity: 0.33;
    pointer-events: none;
  }

  img {
    height: 36px;
    width: 36px;
    border: 1px solid $theme-border-color;
    border-radius: 50%;
    padding: 8px;
    opacity: 1;

    &:hover {
      background-color: #fabc2c;
    }

    @media (max-width: $mobile-devices-max-width+px) {
      height: 30px;
      width: 30px;
    }
  }
}

.section-padding {
  padding: 54px 0;

  @media (max-width: $mobile-devices-max-width+px) {
    padding: 34px 0;
  }
}

@media (min-width: $tablet-devices-min+px) {
  .d-none-tablet-above {
    display: none;
  }
}

@media (max-width: $mobile-devices-max-width+px) {
  .d-none-mobile {
    display: none !important;
  }

  .flex-direction-column-mobile {
    flex-direction: column;
  }

  .flex-direction-column-reverse-mobile {
    flex-direction: column-reverse;
  }

  .mobile-theme-button {
    height: 36px;
  }
}

@media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
  .flex-direction-column-tablet {
    flex-direction: column;
  }
}

.form-group {
  label {
    // margin-left: 5px;
    @extend %light-font;
    font-size: (14 / $base-font-size) + rem;
  }

  input {
    &.form-control {
      // height: 56px;
      color: #2e3151 !important;
      width: 100% !important;
      &:focus {
        // border-color: rgba(var(--primary-color), 1);
        // box-shadow: rgba(var(--primary-color), 1);
        outline: none;
        border: 1px solid #408dfb !important;
      }
    }
  }

  textarea {
    resize: none;
    color: #2e3151 !important;
  }

  .form-control {
    @extend %placeholder;
    color: #2e3151 !important;
    &::placeholder {
      color: #dadce0;
    }
  }
}
.form-control {
  @extend %placeholder;
  color: #2e3151 !important;
  &::placeholder {
    color: #dadce0;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #408dfb !important;
    box-shadow: 0 0 0 0.2rem rgba(68, 74, 156, 0.28);
  }
}
.cursor-pointer {
  cursor: pointer;
}

.small-font {
  font-size: (12 / $base-font-size) + rem;
}

.display-inline-block {
  display: inline-block;
}

.margin-24 {
  margin: 24px 0;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

select {
  &.reset-styling {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    //background-image: url("/svg/generic-icons/dropdown-caret.svg");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 30px;
  }
}

.flex-gap-16px {
  gap: 16px;
}
// .pagination-wrapper {
//   padding: 20px 0 0px;
//   .page-link {
//     color: $theme-font-primary-color;
//     border: none;
//   }
//   ul.pagination {
//     li {
//       &.disabled {
//         display: none;
//       }
//       a {
//         color: $theme-font-primary-color;
//         border: none;
//         padding: 5px;
//         margin-right: 3px;
//         &[aria-label="Go to next page"] {
//           color: $theme-primary-color;
//         }
//       }
//       &.active {
//         a {
//           color: $theme-primary-color;
//         }
//       }
//     }
//   }
// }
.pagination {
  justify-content: center;
  margin-right: 1.5rem;

  li {
    @extend %display-inline-block;

    &:nth-child(1),
    &:nth-child(2),
    &:last-child,
    &:nth-last-child(2) {
      a {
        background-color: transparent;
        font-size: 1rem;
        width: initial;
        height: initial;
        font-weight: $bold-font-weight;
      }

      &:hover {
        a {
          background-color: transparent;
          // color: $theme-secondary-color;
          color: #893297;
        }
      }
    }

    &.active {
      a {
        background-color: #893297 !important;
        color: $white-color;
      }
    }

    a {
      @extend %display-inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      background-color: #f8f8f8;
      color: #5a2d5c;
      margin: 0 5px;
      border-radius: 50%;
      line-height: 30px;
      font-size: 12px;
      @extend %transition;
    }
  }
}

input {
  &.form-control {
    // height: 44px;
    height: 41px;
    border: 1px solid $theme-border-color;
    border-radius: 8px;
  }
}

.form-error {
  color: rgb(236, 55, 55);
  margin-top: 2px;
  display: block;
  font-size: 12px;
  margin: 0;
  opacity: 0.8;
}
.form-label-error {
  color: #b94a48;
  font-size: 14px;
  margin: 0;
  margin-bottom: 4px;
}
.form-label__14 {
  color: #5f6368;
  font-size: 14px;
  margin: 0;
  margin-bottom: 4px;
}
.form-label-error_red {
  color: rgb(236, 55, 55);
  font-size: 14px;
  margin: 0;
  margin-bottom: 4px;
}
.form-label {
  color: #5f6368 !important;
  // margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}

.search-text-box-wrapper {
  img {
    position: absolute;
    right: 10px;
  }

  .search-img {
    img {
      position: absolute;
      left: 0%;
      top: 50%;
      transform: translate(50%, -50%);
      width: 20px;
    }
  }

  input.form-control {
    padding-left: 35px;

    &::placeholder {
      color: #bec6d2;
    }

    &.search-box {
      height: 36px;
      width: 364px;
      border: 1px solid #dadce0;
      border-radius: 4px;
      // box-shadow: 4px 4px 10px rgb(0 0 0 / 29%);
    }
  }
}

.footer-wrapper {
  align-items: center;
  margin: 0px 0 24px;
  border-bottom: 1px solid $theme-border-color;
  padding-right: 24px;
  border-bottom: 1px solid $theme-border-color;
}
.footer-wrapper-new {
  position: absolute;
  left: 0;
  right: 0;
}
.css-1s2u09g-control {
  height: 40px;
  background-color: #fff;
}

.form-error {
  margin-top: 4px;
}

.default-checkbox {
  opacity: 0;
  visibility: hidden;
}
.custom-checkbox--bg-red {
  border: 1px solid $theme-border-color;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  display: flex;
}
.default-checkbox:checked ~ .custom-checkbox--bg-red {
  background-color: #d7242e;
  border-radius: 4px;

  .uploaded-tick {
    display: block;
  }
}
.default-checkbox:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.custom-checkbox--bg-blue {
  border: 1px solid $theme-border-color;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  display: flex;
}
.default-checkbox:checked ~ .custom-checkbox--bg-blue {
  background-color: #1a73e8;
  border-radius: 4px;

  .uploaded-tick {
    display: block;
  }
}

.default-checkbox:checked ~ .custom-checkbox {
  background-color: #1a73e8;
  border-radius: 4px;

  .uploaded-tick {
    display: block;
  }
}

// .default-radio {
//   opacity: 0;
//   visibility: hidden;
// }
// .custom-radio--bg-red {
//   border: 1px solid $theme-border-color;
//   height: 18px;
//   width: 18px;
//   border-radius: 4px;
//   cursor: pointer;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 2;
//   background-color: #fff;
//   display: flex;
// }
// .default-radio:checked~.custom-radio--bg-red {
//   background-color: rgba(var(--primary-color), 0.06); //dynamic hover color according to theme
//   border-radius: 4px;

//   .uploaded-tick {
//     display: block;
//   }

// }
// .active{
//   background-color: rgba(var(--primary-color), 0.06); //dynamic hover color according to theme
// }
// .default-radio:checked~.custom-radio {
//   background-color: #1a73e8;
//   border-radius: 4px;

//   .uploaded-tick {
//     display: block;
//   }

// }

.form-radio {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

// custom-radio
.custom-radio-button-input:checked ~ .radio-wrapper {
  border-color: rgba(var(--primary-color), 1);

  .custom-radio {
    background-color: rgba(var(--primary-color), 1);
  }
}

.radio-wrapper {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid $theme-font-heading-color;
  cursor: pointer;
  @extend %transition;

  &:hover {
    border-color: rgba(var(--primary-color), 1);

    .custom-radio {
      background-color: rgba(var(--primary-color), 1);
    }
  }
}

.custom-radio {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid $white-color;
  margin: auto;
}

.transition {
  transition: all 0.5s ease-in-out;
}

.form-group input.form-control:focus {
  // border-color: rgba(var(--primary-color), 0.06);
  border-color: $theme-border-color;
  box-shadow: none;
}

.table-hover-row-color {
  &:hover {
    background-color: rgba(
      var(--primary-color),
      0.06
    ); //dynamic hover color according to theme
    // background-color: #f7faff;
    cursor: pointer;
  }
}
.resend-btn {
  border: none;
  background-color: transparent;
  color: $theme-secondary-color;
  text-transform: uppercase;
  margin-left: 24px;
}
.resend-btn img {
  margin: 0 auto 4px;
}

.react-date-picker__wrapper {
  border-bottom: 1px solid $theme-border-color;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  cursor: pointer;
  padding-bottom: 4px;
}

.release-details-text {
  min-width: 300px;
}

.color-picker-component {
  z-index: 1;
}

//comments css
.comments-popup-wrapper__comments-title-color {
  margin: auto;
  color: $white-color;
}

.comments-popup-wrapper__comments-wrapper {
  height: 42px;
  width: 42px;
  background-color: #689f38;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment_text {
  color: white;
  font-size: 500;
}

.comments-popup-wrapper__comments-title {
  font-weight: $bold-font-weight;
  margin-right: 8px;
  text-transform: capitalize;
  font-size: 16px;
}
.comments-popup-wrapper__comments-list-item {
  position: relative;
  padding-right: 25px;
  font-size: 12px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -12px;
    height: 16px;
    width: 1px;
    background-color: $theme-border-color;
  }
}
.comments-popup-wrapper__icon {
  margin: auto;
  font-size: 16px;
  font-weight: $medium-font-weight;
  color: $white-color;
}
.comments-popup-wrapper__comments-text {
  color: #5f6368;
  font-weight: $normal-font-weight;
  font-size: 12px;
  margin-bottom: 16px;
}
.comments-popup-wrapper__comeent-text--comment {
  color: #5f6368;
  font-weight: $normal-font-weight;
  font-size: 16px;
}
// .react-datepicker-wrapper {
// 	min-width: 188px;
// 	max-width: 188px;
// 	width: 100%;
// }
.react-datepicker__input-container input.form-control {
  // background-color: transparent;
  background-color: white !important;
  min-width: 188px !important;
  border: none !important;
  // max-width: 188px !important;
  width: 100%;
}
// .react-datepicker__input-container input.form-control {
// 	min-width: 345px !important;
// 	max-width: 345px !important;

// }

.react-datepicker-popper {
  // z-index: 7 !important;
  z-index: 9 !important;
}

.footer-wrapper-new {
  position: absolute;
  left: 0;
  right: 0;
}

//
.react-datepicker-wrapper {
  // border: 1px solid $theme-border-color;
  // border-radius: $base-border-radius;
  // cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  // &:hover {
  //   border-color: #1a73e8;
  // }
  min-width: 105px;
}

.react-date-picker__inputGroup__input {
  // padding: 0 12px;
  padding: 0 10px;
  border-radius: $base-border-radius;
  border: $theme-border-color;
  height: 34px;
  cursor: pointer;
  min-width: 165px;
  max-width: 312px;
  width: 100%;
  &--location {
    height: 40px;
  }
  &:disabled {
    background-color: #e9ecef;
    z-index: -2;
  }
}

.react-date-picker__inputGroup__input_dashboard {
  // padding: 0 12px;
  padding: 0 10px;
  border-radius: $base-border-radius;
  border: 1px solid $theme-border-color;
  height: 34px;
  cursor: pointer;
  // min-width: 165px;
  // max-width: 312px;
  // width: 100%;
  width: 180px;
  font-size: 14px;
  &--location {
    height: 40px;
  }
  &:disabled {
    background-color: #e9ecef;
    z-index: -2;
  }
}

.react-date-picker__inputGroup__input_modal {
  // padding: 0 12px;
  padding: 0 10px;
  border-radius: $base-border-radius;
  border: $theme-border-color;
  height: 34px;
  cursor: pointer;
  min-width: 165px;
  // max-width: 312px;
  width: 100%;
  &--location {
    height: 40px;
  }
  &:disabled {
    background-color: #f7f7f7;
    z-index: -2;
  }
}
.react-date-picker__inputGroup {
  display: flex;
  align-items: center;
}

.map-vendor__footer-image {
  left: 0;
  right: 0;
  bottom: 0;
}
.map-vendor__fixed-footer {
  left: 14%;
  right: 0;
  bottom: 0;
  position: fixed;
}
.user-details-wrapper__sticky {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $white-color;
}

.hidden-row {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.hidden-row--visible {
  max-height: 100vh;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  background: #fff;
  // border: 1px solid #3498db;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  margin: 0.4rem;
  background: #fff;
}
.margin-left-16 {
  margin-left: 16px;
}

.configure-wrapper__form-control .css-1s2u09g-control {
  height: initial !important;
  width: 100%;
  max-width: 100%;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  margin-bottom: 10px !important;
}

.configure-wrapper__form-control_modal {
  .css-1s2u09g-control {
    height: initial !important;
  }
  .css-1pahdxg-control {
    width: 250px !important;
  }
  .css-26l3qy-menu {
    width: 250px !important;
  }
}

.select-grid {
  .select-input-grid-item {
    margin-right: 22px;
    // margin-top: 14px;
    width: 230px;
    .label {
      margin-bottom: 8px;
    }
  }
}

.modal-grid {
  display: flex;
  flex-wrap: wrap;
  .modal-input-grid-item {
    // margin-right: 22px;
    margin-top: 14px;
    width: 318px;
    .label {
      margin-bottom: 8px;
    }
    label {
      color: $theme-font-primary-color;
    }
  }
}

.common-grid {
  display: flex;
  flex-wrap: wrap;
  .common-input-grid-item {
    // margin-right: 22px;
    // margin-top: 14px;
    width: 318px;
    .label {
      margin-bottom: 8px;
    }
    label {
      color: $theme-font-primary-color;
    }
  }
}
.model-grid__form-control {
  .css-1s2u09g-control {
    height: initial !important;
  }
  .css-1pahdxg-control {
    width: 318px !important;
  }
  .css-26l3qy-menu {
    width: 318px !important;
  }
}
.custom-file-upload-popup {
  width: 100%;
  border: 1px dashed #ccc;
  display: inline-block;
  padding: 24px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  cursor: pointer;
  p {
    text-align: center;
  }
}
//calender grid view issues
.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
  z-index: 0;
}
.subtext {
  font-size: 12px;
  color: #808080;
}
.subtext_popup {
  font-size: 10px;
  color: #808080;
  margin-top: 2px;
}
.subtext-header {
  font-size: 13px;
  color: #626567;
  // margin-top: 14px;
}

.grid-multi {
  display: flex;
  flex-wrap: wrap;
  .input-grid-item {
    margin-right: 22px;
    margin-top: 14px;
    width: 100% !important;
    min-height: 200px;
    .label {
      margin-bottom: 8px;
    }
    .css-1s2u09g-control {
      height: initial !important;
    }
    .css-1pahdxg-control {
      width: 50% !important;
      min-height: 50px !important;
    }
    .css-26l3qy-menu {
      width: 50% !important;
      min-height: 50px !important;
    }
  }
}

.chart-container {
  // margin: 3%;
  margin-top: 3%;
  margin-left: 3%;
  display: flex;
  align-items: center;

  .chart-section {
    margin-left: 30px;
    height: 20%;
    width: 35%;
    border: 1px solid #dadce0;
  }
  .tab-section {
    margin-left: 30px;
    width: 55%;
    height: 20%;
    border: 1px solid #dadce0;
  }
}
.table_download_container {
  display: flex;
}
.popup-margin-bottom {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  // margin-top: 10px;
  display: flex;
  align-items: center;
  .circle {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: green;
    margin-right: 10px;
    position: relative;
    .text {
      position: absolute;
      top: 6px;
      right: 11px;
      color: white;
      font-weight: 500;
    }
  }
}
.entity_chart-container {
  margin: 2%;
  justify-content: space-around;
  display: flex;
  .entity_float_left {
    float: left;
    width: 30%;
    border: 1px solid #efefef;
  }
  .entity_float {
    // float: right;
    // width: 32%;
    // border: 1px solid #efefef;
    .entity-header-container {
      margin: 1%;
      display: flex;
      flex-wrap: wrap;
      .entity-header {
        padding: 10px;
        background-color: rgba(var(--primary-color), 0.1);
        color: rgba(var(--primary-color), 1);
        margin-left: 10px;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
        &.active {
          background-color: rgba(var(--primary-color), 1);
          color: white !important;
        }
      }
      .entity-header_text {
        font-size: 14px;
      }
    }
  }
}
.text_entity {
  font-size: 16px;
  color: green;
  margin-left: 10px;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #efefef;
  min-height: 200px;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
  margin: 8px;
}
.table-header {
  // border-top: 1px solid #dadce0;
}

//manage audit
.manage_audit_chart-view {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

//manage audit filter
.model-grid__form-control {
  .css-1s2u09g-control {
    height: initial !important;
  }
  .css-1pahdxg-control {
    width: 318px !important;
  }
  .css-26l3qy-menu {
    width: 318px !important;
  }
}

//------------------ Fixed footer ------------------------//

.fixed_footer_wrapper {
  width: 100%;
  height: 50px;
  z-index: 10;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 0 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  // align-items: center;
  // margin: 0px 0 24px;
  border-top: 1px solid $theme-border-color;
  // padding-right: 24px;
  // border-bottom: 1px solid $theme-border-color;
}
.fixed_moda_footer_wrapper {
  width: 100%;
  height: 70px;
  z-index: 10;
  bottom: 0;
  right: 0px;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  // align-items: center;
  // margin: 0px 0 24px;
  border-top: 1px solid $theme-border-color;
  // padding-right: 24px;
  // border-bottom: 1px solid $theme-border-color;
}

//------------------- table global css ----------------------//
//   table {
// 	table-layout: fixed;
//   }
//   th,
//   td {
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// 	box-sizing: border-box;
//   }
//   th:after,
//   th:before {
// 	content: "";
// 	position: absolute;
// 	left: 0;
// 	width: 100%;
//   }

//   th:before {
// 	top: -1px;
// 	border-top: 1px solid rgb(223, 227, 235);
// 	border-bottom: 1px solid rgb(223, 227, 235);
//   }

//   th:after {
// 	bottom: -1px;
// 	border-bottom: 2px solid rgb(223, 227, 235);
// 	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
//   }

//-------------- file upload ----------------//
.custom-inputfile {
  // border: 1px solid $theme-border-color;
  border: none !important;
  height: 44px;
  line-height: 44px;
  // padding: 0 16px;
  border-radius: $base-border-radius;
  // gap: 16px;
  cursor: pointer;
  // min-width: 345px;
  margin-top: 4px;
  // justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
.custom-inputfile__text {
  height: 26px;
  line-height: 26px;
  // padding: 0px 16px;
  // background-color: #f7f7f7;
  display: inline-block;
}

.left-item,
.right-item {
  display: flex;
  flex-direction: column;
}

.left-item {
  flex: 1;
}

.right-item {
  flex: 1;
}
.main_popup_container {
  overflow: auto;
  max-height: calc(100vh - 140px);
  height: calc(100vh - 140px);
}

.icon_circle {
  border: 1px solid #dadce0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name_circle {
  border: 1px solid white;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c8ced8;
  min-height: 35px;
  min-width: 35px;
}

.box_shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.css-1s2u09g-control {
  height: initial !important;
}
.css-1pahdxg-control {
  width: 50% !important;
  min-height: 50px !important;
}
.css-26l3qy-menu {
  width: 50% !important;
  min-height: 50px !important;
}

//travel-document popup- css

.travel-document-info-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 10vh;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 5px;
  .travel-attachements {
    display: flex;
    flex-wrap: wrap;
    .travel-attachements-box {
      height: 150px;
      width: 150px;
      border-radius: 5px;
      overflow: hidden;
      .travel-attachements-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.swiper-pagination-bullets {
  display: none;
}

// datepicker CSS
.react-datepicker {
  font-family: $font-family !important;
  position: relative;

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: rgba(39, 110, 241, 0.5);
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(39, 110, 241, 1);
  }

  .react-datepicker__day:hover {
    background-color: rgba(39, 110, 241, 0.7);
  }

  .react-datepicker__day--selected {
    background-color: rgba(39, 110, 241, 1);
  }
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__header {
      .react-datepicker__header__dropdown {
        text-align: left;
        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
          margin: 0;
        }

        .react-datepicker__month-dropdown-container {
          width: calc(70% - 5px);
          margin-right: 5px;
          position: relative;
          select {
            width: 100%;
            font-family: $font-family !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #dadce0;
            border-radius: 4px;
            color: #3c4043;
            font-size: 13px;
            padding: 4px 20px 4px 4px;
            position: relative;
            width: 100%;
          }

          &:after {
            content: " ";
            border-color: #dadce0 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            display: block;
            height: 0;
            position: absolute;
            right: 10px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 0;
          }
        }

        .react-datepicker__year-dropdown-container {
          width: calc(30% - 5px);
          margin-left: 5px;
          position: relative;

          select {
            width: 100%;
            font-family: $font-family !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #dadce0;
            border-radius: 4px;
            color: #3c4043;
            font-size: 13px;
            padding: 4px 20px 4px 4px;
            position: relative;
            width: 100%;
          }

          &:after {
            content: " ";
            border-color: #dadce0 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            display: block;
            height: 0;
            position: absolute;
            right: 5px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 0;
          }
        }
      }

      .react-datepicker__year-read-view,
      .react-datepicker__month-read-view,
      .react-datepicker__month-year-read-view {
        width: 100%;
        font-family: $font-family !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid #dadce0;
        border-radius: 4px;
        color: #3c4043;
        font-size: 13px;
        padding: 4px 20px 4px 4px;
        position: relative;
        width: 100%;
        visibility: visible !important;
      }

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow {
        display: none;
      }
    }

    //for option of add trips calendar picker
    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected,
    .react-datepicker__month-year-option--selected {
      left: 2px;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
      width: 100%;
      left: 0;
      background-color: $white-color;
      border-radius: 0;

      .react-datepicker__month-option,
      .react-datepicker__year-option {
        text-align: left;
        border-radius: 0 !important;
        padding-left: 15px;
        &:hover {
          background-color: #1967d2 !important;
          color: $white-color;

          .react-datepicker__navigation--years-upcoming,
          .react-datepicker__navigation--years-previous {
            &:before {
              border-color: $white-color;
            }
          }
        }
      }
    }

    .react-datepicker__year-dropdown {
      .react-datepicker__year-option {
        &:hover {
          background-color: transparent;
          .react-datepicker__navigation--years-upcoming,
          .react-datepicker__navigation--years-previous {
            &:before {
              border-color: $white-color;
            }
          }
        }

        .react-datepicker__navigation--years-upcoming {
          position: relative;
          top: 0;
          left: -8px;

          &:before {
            content: "";
            display: block;
            height: 9px;
            width: 9px;
            border-color: #dadce0;
            border-style: solid;
            border-width: 3px 0px 0 3px;
            transform: rotate(45deg);
            margin: 15px auto 0;
          }
        }

        .react-datepicker__navigation--years-previous {
          position: relative;
          top: 0;
          left: -8px;

          &:before {
            content: "";
            display: block;
            height: 9px;
            width: 9px;
            border-color: #dadce0;
            border-style: solid;
            border-width: 0px 3px 3px 0px;
            transform: rotate(45deg);
            margin: 7.5px auto 0;
          }
        }
      }
    }
  }
}

.mt-35px {
  margin-top: 35px !important;
}

.create-report-popup-wrapper {
  .custom-form-control {
    &:disabled {
      background-color: #f2f2fc;
      color: #3f415f !important;
    }
  }
}

.associated-trips-wrap {
  .associate-trips-trip-info {
    border-left: 1px solid $theme-border-color;
  }

  .associated-trip-itenaries {
    .itenaries-tags {
      position: relative;
      margin-right: 16px;

      .itenaries-tags-count {
        position: absolute;
        color: #fff;
        background: rgb(131, 120, 195);
        height: 15px;
        width: 15px;
        text-align: center;
        border-radius: 15px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -6px;
        right: -6px;
      }
    }
  }
}

.info-dropdown {
  position: relative;
  .info-dropdown-id {
    margin-bottom: 4px;
    color: #4a5158;
    font-weight: $normal-font-weight;
  }

  .info-dropdown-name {
    font-size: 14px;
    font-weight: $medium-font-weight;
    color: #495057;
    margin-bottom: 4px;
  }

  .info-dropdown-type {
    color: #6a6e9a;
    font-size: 12px;
  }

  .info-dropdown-amount {
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    color: #6a6e9a;
  }
}

.btn-primary {
  background: #893297 !important;

  &:hover {
    background: #5a2d5c !important;
  }
}

a {
  color: #1966a1;

  &:hover {
  }
}

.noti-warning {
  padding: 10px 15px;
  margin: 0 1rem;
  width: auto;
  border-left: 4px solid #0b57d0;
  background: aliceblue;
}

.info-warning {
  background-color: #ffe7ca;
  border-left: 4px solid #ff8a00;
  margin: 0 1rem;
  padding: 10px 15px;
}
