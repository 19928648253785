.dropdown__title {
  font-weight: 300;
  line-height: 1;
}

.btn-secondary {
  background-color: transparent !important;
  border-color: transparent;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    border-color: transparent;
  }
}
.show > .btn-secondary.dropdown-toggle {
  border-color: transparent;
}
.btn {
  padding: 0 8px;
  border: none;
}
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.dropdown-menu.show {
  z-index: 999 !important;
}
.dropdown-menu {
  z-index: 999 !important;
  padding: 0;
  transform: translate3d(0px, -9.5px, 0px) !important;
  .dropdown-item {
    font-size: 14px;
    padding: 10px 16px;

    color: #5f6368;
    &:hover {
      color: #1a73e8;
      background-color: rgba($color: #1a73e8, $alpha: 0.06);
    }
    &.active {
      color: #1a73e8;
      background-color: rgba($color: #1a73e8, $alpha: 0.06);
    }
  }
}
.dropdown-header-title {
  font-weight: 400;
  font-size: 14px;
  color: #5f6368;
}
.dropdown {
  position: relative;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   width: 1px;
  //   height: 15px;
  //   left: 0;
  //   transform: translateY(-50%);
  //   background-color: #ffffff;
  // }
}

.dropdown-toggle::after {
  margin-left: 8px;
  vertical-align: 2px;
}
