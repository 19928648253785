@import "@scss/common/_variables.scss";

.inner-page-navigation-with-back-cta-buttons__nav-list-item_trip.active {
  color: rgba(var(--button-primary), 1);
  // padding-bottom: 16px;
  // border-bottom: 2px solid rgba(var(--primary-color), 1);
  // background-color: rgba(var(--primary-color), 0.06);
  background-color: rgba(var(--button-primary), 0.1);
}
.inner-page-navigation-with-back-cta-buttons__nav-list-item_trip.active:after {
  background-color: rgba(var(--primary-color), 1);
  padding: 7px;
}

.inner-page-divider {
  border-right: 1px solid #dadce0;
}

.create-trip-sticky-footer {
  height: 60px;
  // border-top: 1px solid #dadce0;
  padding: 0 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.create-trip-sticky-header {
  height: 50px;
  border-bottom: 1px solid #dadce0;
  padding: 0 16px;
  background: #fff;
}

.trip-vertical-wrapper {
  height: calc(100vh - 64px - 51px - 60px);
  overflow-y: hidden;
}

.inner-page-navigation-with-back-cta-buttons__nav-list-item_trip {
  // margin-right: 12px;
  padding: 0 32px;
  gap: 8px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: 64px;
  text-wrap: nowrap;
  line-height: normal;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  background: #fff;

  &:hover {
    // color: rgba(var(--button-primary), 1);
    background-color: rgba(var(--button-primary), 0.1);
  }

  &:last-child {
    margin: 0;
  }
}
.vr-line {
  height: 40px;
  background-color: #dadce0;
  width: 1px;
  margin-right: 10px;
}
.trip-count-section {
  height: 20px;
  width: 20px;
  background-color: rgba(var(--primary-color), 1);
  position: absolute;
  border-radius: 50%;
  right: -4px;
  top: -7px;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-trips-sticky-header {
  .inner-page-navigation-with-back-cta-buttons {
    margin: 0 135px;
    padding: 31px 0 24px;

    .deeptravel-button--header-buttons {
      margin-top: 0;
      margin-bottom: 0;
    }

    .line-seperator {
      position: relative;
      &:after {
        content: "";
        height: 54px;
        width: 1px;
        display: block;
        background-color: $theme-border-color;
        position: absolute;
        left: 191px;
        top: -10px;
      }
    }

    .ml-16 {
      margin-left: 16px;
    }

    .inner-page-navigation-with-back-cta-buttons__nav-list-item_trip {
      padding: 11px 16px;
      font-size: 14px;
      line-height: 18px;
      margin-right: 16px;
      height: 40px;
      display: flex;

      p {
        margin-left: 4px;
      }
    }
  }
}

.trips_main_section {
  .trips_flights_cotnainer {
    margin: 32px auto;
    width: 100%;

    .form_trips_wrapper_container {
      width: calc(50% - 24px);
      max-width: 364px;
      margin-right: 24px;
      margin-bottom: 16px;

      // &:nth-child(odd)  {
      //   margin-right: 12px;
      // }

      // &:nth-child(even)  {
      //   margin-left: 12px;
      // }

      label {
        font-size: 14px;
        line-height: 18px;
        font-weight: $medium-font-weight;
        margin-bottom: 8px;
        margin-left: 0;
      }

      .error-text {
        margin-top: 0;

        .srv-validation-message {
          margin-top: 8px;
        }
      }
    }
  }

  .trips-flight-tab-main-container {
    margin: 0 135px;

    .multiple-dyno-wrap {
      border-bottom: 1px solid $theme-border-color;
      padding: 30px 0 30px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0px;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    .trip-selector-wrapper {
      .trip-selector_checkbox-wrapper {
        margin-right: 8px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .form-radio {
          position: absolute;
          left: 10px;
        }

        .form-radio:checked + .trip-selector {
          background-color: #f8fbff;
        }

        .checkbox-inner-label {
          margin-left: 10px;
        }

        .radio-button-container {
          cursor: pointer;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkmark {
            position: absolute;
            top: 8px;
            left: 10px;
            height: 20px;
            width: 20px;
            background-color: rgba(0, 0, 0, 0);
            border-radius: 50%;
            border: 2px solid $theme-border-color;
            transition: all 0.3s;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }
        }

        /* Show the indicator (dot/circle) when checked */
        .radio-button-container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .radio-button-container .checkmark:after {
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #276ef1;
        }

        .radio-button-container input:checked ~ .checkmark {
          background-color: rgba(0, 0, 0, 0);
          border-color: #276ef1;
        }

        .radio-button-container input:checked ~ .trip-selector {
          background-color: aliceblue;
        }
      }
      .trip-selector {
        padding: 8px 8px 8px 28px;
        border-color: #e8f0fe !important;

        .form-radio {
          width: 20px;
          height: 20px;
        }

        p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .flight-preference-wrap {
      border-color: #e8f0fe !important;
      padding: 8px;
      background: #fff;
      // margin-left: 8px;

      .flight-preference-wrap_text {
        font-size: 14px;
        line-height: 18px;
        color: $theme-font-primary-color;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
  }
}

.trips-hotels-tab-main-container {
  margin: 0 135px;

  .multiple-dyno-wrap {
    border-bottom: 1px solid $theme-border-color;
    padding: 30px 0 30px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0px;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.trips-car-tab-main-container {
  margin: 0 127px;

  .trips-car-datepicker {
    .flight_date_section {
      width: 225px;

      .trips_multi_city_datepicker {
        width: 148px;
      }
    }
  }

  .tripcity-with-no-margin-right {
    .hotel_search_section {
      margin-right: 0;
    }
  }

  .dropdownselect-form {
    .selected-value-box {
      height: 56px;
      padding: 16px 8px;

      .right_container {
        top: 14px;
        right: 8px;
      }
    }
  }

  .multiple-dyno-wrap {
    border-bottom: 1px solid $theme-border-color;
    padding: 30px 0 30px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0px;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .driver-required-wrap {
    margin-left: 24px;
  }

  .choose-car-type-wrapper {
    .dropdownselect-form {
      .selected-value-box {
        width: 284px !important;
      }
    }
  }

  .padd-0-8 {
    padding: 0 8px;
  }
}

.trips-train-tab-main-container {
  margin: 0 135px;

  .multiple-dyno-wrap {
    border-bottom: 1px solid $theme-border-color;
    padding: 30px 0 30px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0px;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.trips-bus-tab-main-container {
  margin: 0 135px;

  .trips-car-datepicker {
    .flight_date_section {
      width: 225px;

      .trips_multi_city_datepicker {
        width: 148px;
      }
    }
  }

  .tripcity-with-no-margin-right {
    .hotel_search_section {
      margin-right: 0;
    }
  }

  .dropdownselect-form {
    .selected-value-box {
      height: 56px;
      padding: 16px 8px;

      .right_container {
        top: 14px;
        right: 8px;
      }
    }
  }

  .multiple-dyno-wrap {
    border-bottom: 1px solid $theme-border-color;
    padding: 30px 0 30px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0px;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .driver-required-wrap {
    margin-left: 24px;
  }

  .choose-car-type-wrapper {
    .dropdownselect-form {
      .selected-value-box {
        width: 284px !important;
      }
    }
  }
}

.error-tabs {
  background: rgba(255, 0, 0, 0.137);
  color: red;
}
