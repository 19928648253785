@import "@scss/common/variables";

.effects {
  background-color: rgba(var(--primary-color), 0.06);
  background-color: #fdf5f7;
  // background-color: #f2f3ff;
  color: rgba(var(--primary-color), 1);
  // color: #408dfb;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(var(--primary-color), 1);
    // background-color: #408dfb;
  }
}
.trip-label {
  color: #5f6368 !important;
  // margin-left: 5px;
  // font-size: 12px;
  // margin-bottom: 4px;
  // font-weight: 500;

  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 0;
}
//----------------------- Trips Create ------------------//

.trip_travel_container {
  // border: 1px solid #dadce0;
  border-radius: 8px;
  // padding: 10px 15px;
  padding: 0;
  // margin-top: 5px;
  margin-top: 16px;
  // background-color: #fbfbfb;
}
.trips_main_section {
  overflow: auto;
  //132px
  // max-height: calc(100vh - 180px);
  // height: calc(100vh - 180px);
  // max-height: calc(100vh - 210px);
  height: calc(100vh - 176px);
}
.form_flex_container {
  display: flex;
  flex-wrap: wrap;
}

.form_trips_wrapper {
  width: 70%;
  flex-wrap: wrap;
  display: flex;
  margin: 0 auto;

  @media only screen and (min-width: 1800px) {
    width: 72%;
  }
}
.form_trips_wrapper_container {
  width: 47%;
  margin-right: 3%;
  // height: 18%;
}

.form_users_wrapper_container {
  width: 47%;
  margin-right: 3%;
  min-height: 12%;
}

.trips_flights_cotnainer {
  width: 93%;
  margin: 0 auto;
}

.flight_search_section {
  width: 284px;
  // height: 45px;
  height: 56px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  //Added margin bottom because for smaller screen the to date is getting down for roundtrip
  margin-bottom: 16px;
  padding: 16px 8px;
  border-radius: 8px;
}
.flight_date_section {
  // width: 230px;
  width: 244px;
  height: 56px;
  // height: 45px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  //Added margin bottom because for smaller screen the to date is getting down for roundtrip
  margin-bottom: 16px;
  padding: 16px 8px;

  .trips_multi_city_datepicker {
    // max-width: 172px;
    max-width: 94%;
    min-width: auto;
  }
}

.preference-heading {
  font-size: 16px;
  padding-left: 12px;
  padding: 1rem 0 10px 0;
  border-bottom: 1px solid #dadce0;
  margin: 0 1rem;
  color: #3c4043 !important;
  font-weight: 400;
}

.flight_time_section {
  background-color: white;
  // width: 325px;
  // width: 230px;
  // height: 45px;
  // height: 56px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 8px 8px;
}
.flight_preference_section {
  background-color: white;
  // width: 230px;
  // height: 45px;
  // height: 56px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 8px 8px;
}

.hotel_search_section {
  width: 350px;
  min-width: 350px;
  // height: 45px;
  height: 56px;
  box-shadow: 0px 3px 6px #00000029;
  margin-right: 16px;
  padding: 16px 8px;
}

.hotel_swap {
  position: absolute;
  // left: 25%;
  left: 330px;
  top: 28px;
  z-index: 5;
}

.hotel_date_section {
  width: 230px;
  box-shadow: 0px 3px 6px #00000029;
  height: 45px;
}
.trip-hotel-timepicker {
  width: 200px;
  // height: 45px;
  height: 56px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 16px 8px;
  background: #fff;
}

.swap_item {
  position: absolute;
  right: 47%;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  //Added margin top because for smaller screen the to date is getting down for roundtrip
  margin-top: 8px;
}

.trips_add_icon {
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trips_popup_options_container {
  overflow: auto;
  max-height: 35vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 8px;

  .px-two-rem {
    padding-left: 32px;
  }

  .trips_popup_options {
    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
      background-color: #e7effc;
    }
    &.active {
      background-color: #408dfb;
      p {
        color: white;
      }
    }
  }
}

.trips_popup_airport_container {
  .trips_popup_options {
    &:hover {
      background-color: #e7effc;
    }
    &.active {
      background-color: #408dfb;
      h6,
      p {
        color: white;
        font-weight: 500;
      }
    }
  }
}

.trips_popup_airport_container_main {
  overflow: auto;
  height: 32vh;
  max-height: 32vh;
}
.trip_search_input {
  height: 35px;
  border: none;
  background-color: #ececfc;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 35px;

  &:focus {
    border: 1px solid #408dfb;
    background-color: white;
  }
  &::placeholder {
    color: rgb(200, 198, 198);
  }
}

.trips_search_image {
  height: 18px;
  width: 18px;
  top: 14px;
  left: 14px;
}
.trips_delete_image {
  height: 18px;
  width: 18px;
  top: 12px;
  right: 18px;
}

.trips_multi_city_datepicker {
  border: none !important;
  border-radius: $base-border-radius;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  &:hover {
    border-color: #1a73e8;
  }
  min-width: 205px;
}

.trips_header_bg {
  background-color: #f1f1fc;
  border-radius: 5px;
}
//----------------------- Trips Detail ------------------//

.trips_main_container {
  display: flex;
  .trips_main_container_left {
    width: 65%;
    border-right: 1px solid #dadce0;
  }
  .trips_main_container_right {
    width: 35%;
    overflow: auto;
    max-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
  }
}

.trip_airport_component_wrapper {
  margin-right: 16px;
  > div {
    &:nth-child(1) {
      margin-right: 4px;

      .flight_search_section {
        padding-right: 20px;
      }
    }

    &:nth-child(2) {
      margin-left: 4px;

      .flight_search_section {
        padding-left: 25px;
      }
    }
  }
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

// .react-datepicker{
//   width: 313px !important;
// }

//--------------------Car Section ----------------------------//
.trip_car_container {
  width: 434px;
  max-width: 434px;
}
.car_search_section {
  width: 434px;
  max-width: 434px;
  height: 45px;
  box-shadow: 0px 3px 6px #00000029;
}
//--------------------train Section ----------------------------//

.train_search_section {
  width: 300px;
  max-width: 434px;
  height: 45px;
  box-shadow: 0px 3px 6px #00000029;
}

.flight_time_departure_preference {
  // margin-top: 6px;

  > div {
    margin-right: 16px;
    margin-bottom: 16px;
    &:last-child() {
      margin-right: 0px;
    }
  }
}

.flight_description_wrap {
  textarea {
    height: 56px;
    padding: 16px 8px 8px;
  }
}

// .hotel_city_popup-content {
//   min-width: 350px;
// }

.second_swap-checkin-date {
  .flight_date_section {
    padding: 16px 8px 16px 20px !important;
  }
}

.trips_details_main_container {
  .trips_main_container_left {
    .trips_details_main_container_header {
      padding: 24px 26px;

      .trips_details_main_container_sub-header {
        margin-top: 2px;
      }
    }

    .trips_details_navigation_wrapper {
      .inner-page-navigation-with-back-cta-buttons {
        height: 36px;
        padding: 0;
        margin: 0 26px;

        .inner-page-navigation-with-back-cta-buttons__navigation_left_temp {
          bottom: -0.5px;
          top: 0;
          left: 0;

          .inner-page-navigation-with-back-cta-buttons__nav-list-item {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .active {
          border-bottom: 3px solid #893297;
        }
      }
    }

    .trips_details_navigation_tabs_wrapper {
      .itenary_main_section {
        .itenary_main_section_cards_holder {
          margin: 24px 26px;

          .flight_trip_itenary {
            padding: 24px;
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            .flight_trip_itenary_tripname_wrapper {
              padding-left: 24px;

              .flight_trip_itenary_tripname {
                line-height: 20px;
                &.text_danger {
                  color: #d7242e;
                }
              }

              .flight_trip_itenary_tripname_airportname {
                color: $theme-font-primary-color;
                font-size: 12px;
                line-height: 16px;
              }

              .flight_trip_itenary_centerarrow_holder {
                margin: 0 24px;
              }
            }

            .itenary-flight-icon-wrapper {
              position: absolute;
              left: 16px;
              top: 16px;
            }

            .dashed-border {
              margin: 16px auto;
              background-image: linear-gradient(
                to right,
                $theme-border-color 50%,
                rgba(255, 255, 255, 0) 0%
              );
              background-position: top;
              background-size: 10px 3px;
              background-repeat: repeat-x;
              height: 1px;
              border: 0;
            }

            .flight_trip_itenary_flight_contentdetails_wrapper {
              .flight_trip_itenary_flight_contentdetails {
                margin: 0 16px;
                position: relative;

                &:after {
                  content: "";
                  height: 32px;
                  width: 1px;
                  background-color: $theme-border-color;
                  position: absolute;
                  right: -17px;
                  top: 50%;
                  transform: translateY(-50%);
                }

                &:first-child {
                  margin-left: 0;
                }

                &:last-child {
                  margin-right: 0;

                  &:after {
                    display: none;
                  }
                }

                .header_text__14 {
                  font-size: 14px;
                  line-height: 18px;
                  color: $theme-font-heading-color;
                  font-weight: $medium-font-weight;
                }

                .dot-holder {
                  position: relative;
                  margin: 0 8px 0 12px;

                  &:before {
                    content: "";
                    position: absolute;
                    height: 3px;
                    width: 3px;
                    border-radius: 50%;
                    background-color: $theme-font-primary-color;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                .flight_itenary_details_label {
                  font-size: 12px;
                  line-height: 18px;
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.hotel_trip_itenary {
  .hotel_timing_details_wrap {
    .hotel_timing_details_checkin,
    .hotel_timing_details_checkout {
      .header_text__16 {
        line-height: 20px;
        color: $theme-font-heading-color;
      }

      .hotel_itenary_details_label {
        font-size: 12px;
        line-height: 16px;
        color: $theme-font-primary-color;
      }
    }

    .hotel_trip_itenary_centerarrow_holder {
      margin: 0 24px;
    }
  }
}

.itenary_main_section {
  .itenary_description_wrapper {
    margin-top: 8px;
    padding: 8px;
    text-align: center;
    color: #204891;
    background-color: #e8f0fe !important;
    border: 1px solid #e8f0fe;

    p {
      font-size: 12px;
      line-height: 14px; // To match the height of the blue desc bar
    }
  }
}

.car_trip_itenary {
  .trip_itenary_pickup,
  .trip_itenary_dropoff {
    .pickup-location-name {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;

      &.text_danger {
        color: #d7242e;
      }
    }

    .pickup-location-time {
      font-size: 16px;
      line-height: 20px;
      font-weight: $medium-font-weight;
    }

    .trip_itenary_pickup_label {
      font-size: 12px;
      line-height: 16px;
      margin-top: 3px;
    }
  }
}

.train_trip_itenary_trip_detail {
  .train_trip_itenary_centerarrow_holder {
    margin: 0 24px;
  }

  .train_trip_departure,
  .train_trip_arrival {
    min-width: 160px;

    .train_trip_departure_header {
      font-size: 16px;
      line-height: 20px;
      font-weight: $medium-font-weight;
      color: $theme-font-heading-color;
    }

    .train_itenary_details_label {
      font-size: 12px;
      line-height: 16px;
      color: $theme-font-primary-color;
      margin-top: 3px;
    }
  }
}

.trips_main_container_right {
  .trips_right_container_content_holder {
    padding: 24px 40px;

    .trip-mandate-information {
      .trip-mandate-approval-info {
        font-size: 14px;
        line-height: 18px;
        color: $theme-font-primary-color;
        font-weight: $medium-font-weight;
        margin-bottom: 8.5px;
      }

      .trip-mandate-profile-wrap {
        .profile_circle {
          h5 {
            font-weight: normal;
          }
        }

        h6 {
          font-size: 16px;
          line-height: 18px;
          color: $theme-font-heading-color;
        }
        p {
          margin-top: 3px;
          color: $theme-font-primary-color;
        }
      }

      .trip-mandate-view-approver-flow {
        margin-top: 8.5px;
        h6 {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .trips_mandate_approver_seperator {
        margin: 16.5px 0;
      }

      .trip_mandate_label_text_wrap {
        .trip_mandate_label_holder {
          font-size: 14px;
          line-height: 18px;
          color: $theme-font-primary-color;
          font-weight: $medium-font-weight;
          margin-bottom: 8px;
        }

        .trip_mandate_value_holder {
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .trips_mandate_approver_seperator {
      margin: 16.5px 0;
    }

    .trips_mandate_expese_documents_upload {
      .trips_mandate_expese_documents_upload_label {
        font-size: 14px;
        line-height: 18px;
        color: $theme-font-primary-color;
        font-weight: $medium-font-weight;
      }

      .trips_mandate_expese_documents_addfiles_label {
        font-weight: $medium-font-weight;
        color: #276ef1;
        svg {
          margin-right: 8px;
        }
      }
    }

    .expense_mandate_preview_document_wrapper {
      margin-top: 16.5px;

      .document_list_view {
        margin-bottom: 8px;
        background-color: #f8faff;
        padding: 9px 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .document_list_view_filename {
          font-size: 12px;
          line-height: 18px;
          font-weight: $medium-font-weight;
          color: $theme-font-heading-color;
          margin-left: 4px;
        }

        .delete_small_image_container {
          height: 18px;
          width: 18px;

          .delete_small_image {
            height: 10.5px;
            width: 10.5px;
          }
        }
      }
    }
  }
}
