//delete modal css
@import "@scss/common/variables";

.delete-popup-wrapper__popup {
  margin: auto;
  min-height: 180px;
  // max-height: 292px;
  width: 100%;
  //   box-shadow: 4px 4px 10px #00000029;
  border-radius: 4px;
  // padding: 93px 47px 20px;
  // padding: 34px 47px 39px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.delete-popup-wrapper__popup--no-max-height {
  height: initial;
  padding: 24px;
}

.delete-popup-wrapper__form-group {
  label.delete-popup-wrapper__label {
    color: $black-color;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
  }
}

.delete-popup-wrapper__desc_text {
  color: #5f6368;
  font-size: 16px;
}

.delete-popup-wrapper__cta-wrapper {
  text-align: right;
  //   margin-top: 40px;
  .delete-popup-wrapper__cta-btn {
    font-size: 16px;
    padding: 10px 24px;
    border-radius: 4px;
    border: none;
    border: 1px solid $theme-border-color;
    &--secondary {
      color: rgba(var(--button-primary), 1);
      background-color: $white-color;
      transition: 0.3s all ease-in-out;

      margin-right: 16px;

      &:hover {
        background-color: rgba(var(--button-primary), 1);
        color: $white-color;
        border-color: transparent;
      }
    }

    &--primary {
      background-color: rgba(var(--button-primary), 1);
      color: $white-color;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: #5a2d5c;
        border: 1px solid $theme-border-color;
      }
    }
  }
}
.common-popup-spacing__popup {
  padding: 42px 47px;
}
