@import "@scss/common/normalize";
@import "@scss/common/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}
.main-wrapper-login {
  width: 100%;
  display: flex;
  //height: calc(100vh - 65px);
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.flex-1 {
  flex: 1;
}

// .login-wrapper {
//   // background-color: #364f70;
//   background-color: #0059A8;
//   max-width: 50%;
//   // min-height: 768px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @media screen and (max-width: 768px) {
//     display: none;
//    }
// }
// .login-wrapper img {
//   // margin: 80px 99px 47px;
//   // width: 365px;
//   width: 60%;

// }

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f4f9;
  height: 100vh;
}

.login-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 60%;
  width: 100%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 20px 0;
    display: none;
  }
}

.logo-container {
  width: 100%;
  max-width: 14rem;
  padding: 2rem 0 0 3rem;
}

.login-wrapper .image-wrapper {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .login-wrapper img {
    width: 100%; /* Adjust the width as needed for smaller screens */
  }
}
.login-wrapper h3 {
  color: $white-color;
  text-align: center;
  max-width: 300px;
  width: 100%;
  margin: 0 auto 8px;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 4%;
}
.login-wrapper p {
  color: $white-color;
  margin: 0 auto;
  text-align: center;
  max-width: 419px;
  width: 100%;
}
.form-wrapper img {
  width: 173px;
  float: right;
}
.logo {
  top: 40px;
  right: 40px;
}
.form-wrapper__form {
  margin: 0 auto;
  max-width: 364px;
  width: 100%;
  @media screen and (max-width: 768px) {
    // margin-top: 10% !important;
  }
}

.signin-mobile-logo {
  float: none !important;
  margin: 4rem 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
}
.form-wrapper {
  .form-wrapper__form-common-title {
    margin-bottom: 32px;
    color: $black-color;
    font-weight: 500;
    &--email {
      margin-bottom: 8px;
    }
  }
  .form-wrapper__form-text-description {
    margin-bottom: 40px;
    max-width: 326px;
    width: 100%;
    font-size: 20px;
    color: #423f3f;
  }
}
.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  color: #423f3f;
  margin-bottom: 4px;
  font-size: 14px;
}
.form-group input.login {
  border: 1px solid #e8f0fe;
  max-width: 364px;
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  padding: 0 24px;
}
.form-group .form-control--user-id {
  background-color: #f7f7f7;
  color: #9d9d9d;
  font-size: 16px;
  width: 100%;
  height: 56px;
  line-height: 56px;
}
.form-group input::placeholder {
  color: #9d9d9d;
}
.form-cta-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: 768px) {
    display: block;
  }
}
.form-cta-button__secondary-btn {
  border: none;
  background-color: transparent;
  color: #1966a1;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: right;
    margin-bottom: 8px;
  }
}
.form-cta-button__primary-btn {
  color: $white-color;
  text-align: center;
  height: 56px;
  line-height: 56px;
  max-width: 181px;
  width: 100%;
  border: none;
  background-color: #893297;
  border-radius: 4px;
  cursor: pointer;
  &--reset-btn {
    max-width: 100%;
  }

  &:hover {
    background: #5a2d5c;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    // height: 30px;
    // line-height: 30px;
    max-width: 100% !important;
  }
}
.back-btn {
  display: flex;
  align-items: center;
  max-width: 100px;
  width: 100%;
  color: #1966a1;
  font-size: 14px;
}

.back-arrow {
  height: 24px;
  width: 24px !important;
  margin-right: 8px;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden {
  padding-bottom: 50px;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10px;
}
.swiper-pagination-bullet {
  &.swiper-pagination-bullet-active {
    background-color: #fd9933;
  }
}
.captcha_container {
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.captcha_input {
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #dadce0;
  &:focus {
    border: 1px solid #1a73e8;
  }
}
.captcha_reload {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.login-wrapper {
  .login-left-content-holder {
    margin-top: 47px;

    h3 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      margin: 0 auto 4px;
      max-width: 274px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 200;
    }
  }
}

.main-wrapper-login {
  position: relative;
  width: 100%;
  max-width: 80%;
  border-radius: 1.5rem;
  height: 70%;
  background: #fff;

  @media screen and (max-width: 768px) {
    max-width: 90%;
    height: 90%;
  }

  .login-right-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 16rem;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .login-form-wrapper {
    // background-color: #ffffff;
    width: 50%;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 60%;
    }
    @media screen and (max-width: 768px) {
      padding-top: 50px;
      justify-content: center;
      width: 100%;
    }

    .form-wrapper__form {
      margin: auto 60px auto 127px;
      z-index: 9;

      @media screen and (max-width: 768px) {
        margin: auto 20px 20px;
      }

      .back-btn {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 18px;
        color: #1966a1;
      }

      h2 {
        font-size: 34px;
        line-height: 43px;
        color: #202124;
        font-weight: 500;
        margin-bottom: 12px;
        margin-top: 0;
      }

      p {
        margin-bottom: 24px;
      }

      .login-fields-wrap {
        margin-bottom: 24px;
        label {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 4px;
          margin-left: 0;
          color: #423f3f;
        }

        input {
          border: 1px solid #e8f0fe;
          border-radius: 8px;
          background-color: #fff;
          padding: 18px 24px;
          font-size: 16px;
          line-height: 20px;
          height: 56px;

          &:focus {
            border-color: #e8f0fe !important;
          }

          &::placeholder {
            color: #9d9d9d;
          }
        }

        .form-group {
          margin-bottom: 0;
        }

        .form-error {
          margin: 0;

          .srv-validation-message {
            margin-top: 4px;
          }
        }
      }

      .form-cta-button {
        .form-cta-button__primary-btn {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          padding: 18px;
          text-align: center;
          max-width: 181px;
        }

        .form-cta-button__secondary-btn {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #1966a1;
        }

        &.form-cta-password-reset {
          .form-cta-button__primary-btn--reset-btn {
            max-width: 100%;
            width: 100%;
          }
        }
      }

      &.sent-mail-form-content {
        h2 {
          &.form-wrapper__form-common-title--email {
            margin-bottom: 8px;
          }
        }

        .form-wrapper__form-text-description {
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 40px;
          color: #423f3f;
          font-weight: 300;
          max-width: 100%;
        }

        .form-cta-button__primary-btn--reset-btn {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
