@import "@scss/common/_variables.scss";
.datatable-main-wrapper {
  padding-top: 10px;
  background: #f7faff;
}

.datatable_main_container {
  overflow: auto;
  max-height: calc(100vh - 64px - 50px - 66px);
  height: calc(100vh - 64px - 50px - 66px);
  width: 100%;
  margin: auto;
}
.datatable_main_container_notifiction {
  overflow: auto;
  max-height: calc(100vh - 290px);
  height: calc(100vh - 290px);
  width: 100%;
  margin: auto;
}
.datatable-table_top_container {
  width: 95%;
  margin: auto;
  height: 44px;
  position: sticky;
  background-color: #f4f8ff;
  border: 1px solid #dadce0;
  display: flex;
  align-items: center;
  .datatable-table_top_container_items {
    width: 100%;
  }
}
.datatable_container {
  max-height: calc(100vh - 64px - 60px - 132px - 32px);
  overflow: auto;
  width: 95%;
  margin: auto;
  border-bottom: 1px solid #dadce0;
}

.datatable_table {
  overflow: auto;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  width: 100%;
}
.datatable_table_thead {
  border-radius: 8px 8px 0 0;
  .datatable_table_th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 7;
    height: 60px;
    background-color: #fff;
    transition: all 500ms ease-in;
    font-family: $font-family;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    h6 {
      font-weight: 500;
      color: #02052b;
    }

    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12;
    }

    &:first-child {
      border-radius: 8px 0 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }
  }
}

.datatable_table_th:nth-child(1) {
  text-align: center;
}

.datatable_table_td:nth-child(1) {
  align-items: center;
  text-align: center !important;
  display: flex;
  justify-content: center;
  // width: 70px !important;
  // border-bottom: 1px solid #efefef !important;
  width: auto !important;
}
.datatable_table_td_common:nth-child(1) {
  align-items: center;
  text-align: center !important;
  display: flex;
  justify-content: center;
  // width: 70px !important;
}
.datatable_table_tr {
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  background: #fff;
  &:hover {
    background-color: #f7faff !important;
  }
  .datatable_table_td {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #efefef;

    width: 400px;
    cursor: pointer;
    max-width: 300px;
    h6 {
      font-weight: 500;
      color: #5f6368;
      font-size: 15px;
    }
    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
    }

    .table-icon,
    .table-profile-icon {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
    }

    .table-icon {
      background: #e4f8ff !important;
    }

    .table-profile-icon {
      background: #e0e0e0;
      color: #000;
      margin-right: 8px;
    }
  }
  .datatable_table_td_common {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    // border-bottom: 1px solid #efefef;
    width: 400px;
    cursor: pointer;
    max-width: 300px;
    h6 {
      font-weight: 500;
      color: #5f6368;
      font-size: 15px;
    }
    p {
      font-weight: 400;
      color: #5f6368;
      font-size: 12px;
    }
  }
}
table {
  table-layout: fixed;
}
th,
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  // border-bottom: 1px solid #dadce0;
  top: -1px;
  // border-top: 1px solid #dadce0;
}

th:after {
  bottom: -1px;
  border-bottom: 2px solid #dadce0;
}

//Written by Joseph
.datatable_table {
  .datatable_table_thead {
    .datatable_table_th,
    h6 {
      font-size: 16px;
      line-height: 18px;
      color: $table-head-font-color;
      text-transform: uppercase;
    }

    p {
      text-transform: none;
    }

    .datatable_table_th {
      padding-top: 24px;
      padding-bottom: 16px;
      vertical-align: top;
      background: #fff;
      // background: #f8f8ff;
      p {
        color: $theme-font-primary-color;
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }

  .datatable_table_tbody {
    .datatable_table_tr {
      .datatable_table_td {
        font-size: 16px;
        line-height: 20px;
        color: $theme-font-primary-color;
        height: auto;
        // padding: 17px 10px 15.5px;
        // height: 60px;

        &:nth-child(1) {
          height: 64px;
          display: table-cell; // coz of d-flex the border the was missalligned
          min-width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h6 {
          font-size: 16px;
          line-height: 20px;
          color: $theme-font-primary-color;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          margin-top: 3px;
        }
      }

      &:last-child {
        .datatable_table_td {
          &:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }

      &:last-child {
        .datatable_table_td {
          &:first-child {
            border-radius: 0 0 0 8px;
          }
        }
      }
    }
  }
}

.datatable_main_container,
.datatable_main_container_notifiction {
  padding: 0 26px 10px;
  background: #f7faff;

  .datatable_table {
    .datatable_table_thead {
      .datatable_table_th {
        background: #fff;
      }
    }

    .datatable_table_tr {
      background: #fdfeff;
      // :hover {
      //     background-color: #F7FAFF;
      //     .datatable_table_th, .datatable_table_td {
      //       background: #F7FAFF;
      //     }
      // }
    }
  }
}
