@import "@scss/common/variables";

.effects {
  background-color: rgba(var(--primary-color), 0.06);
  color: rgba(var(--primary-color), 1);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(var(--primary-color), 1);
  }
}

.report-detail-wrapper_container {
  display: flex;
  height: calc(100vh - 70px - 26px);
  overflow: hidden;

  .report-detail-wrapper_left {
    background-color: white;
    flex-basis: 70%;
    border-right: 1px solid #00000029;
    .report-detail-wrapper_left_container {
      background: #f8f9fa;
      min-height: calc(100vh - 64px - 32px);
      // margin: 3%;
      margin-top: 1%;

      .report-detail_header_created {
        font-size: 14px;
        line-height: 16px;
        color: #5f6368;
      }

      .reports-more-details {
        font-size: 20px;
        font-weight: 500;
        color: #202124;
        // position: sticky;
        // top: 0;
      }

      .report-detail-wrapper_left_container_header_section {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        height: 100px;
        background: #f4ede5;
        .expense-detail_headerleft_section {
        }
        .expense-detail_headerright_section {
        }
      }
      .report-detail-wrapper_left_container_list_section {
        overflow: auto;
        padding: 10px;
        // max-height: calc(100vh - 70px - 267px);
        // height: calc(100vh - 70px - 267px);
        max-height: calc(100vh - 70px - 315px);
        height: calc(100vh - 70px - 315px);
        .report-detail-wrapper_left_container_list_item {
          box-shadow: 0px 3px 6px #00000029;
          cursor: pointer;
          min-height: 110px;
          // border: 1px solid #dee2e6;
          border-radius: 8px;
          margin-top: 5px;
          margin-bottom: 5px;
          background-color: white;

          // padding-left: 15px;
          &.active {
            @extend .effects;
          }
          .report-detail-wrapper_header_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          .report-detail-wrapper_desc_text {
            color: #5f6368;
            font-size: 12px;
          }
          .report-detail-wrapper_status_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
          }
        }
      }
      .report-detail-wrapper_left_container_approval_list_section {
        overflow: auto;
        padding: 10px;
        max-height: calc(100vh - 70px - 216px);
        height: calc(100vh - 70px - 216px);
        .report-detail-wrapper_left_container_list_item {
          box-shadow: 0px 3px 6px #00000029;
          cursor: pointer;
          min-height: 110px;
          border: 1px solid $theme-border-color;
          border-radius: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          background-color: white;

          padding-left: 15px;
          &.active {
            @extend .effects;
          }
          .report-detail-wrapper_header_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          .report-detail-wrapper_desc_text {
            color: #5f6368;
            font-size: 12px;
          }
          .report-detail-wrapper_status_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
          }
        }
      }

      .report-detail-wrapper_left_container_approval_list_section_reimbursement {
        overflow: auto;
        padding: 10px;
        max-height: calc(100vh - 70px - 364px);
        height: calc(100vh - 70px - 364px);
        .report-detail-wrapper_left_container_list_item {
          box-shadow: 0px 3px 6px #00000029;
          cursor: pointer;
          min-height: 110px;
          border: 1px solid $theme-border-color;
          border-radius: 5px;
          margin-top: 5px;
          margin-bottom: 5px;
          background-color: white;

          padding-left: 15px;
          &.active {
            @extend .effects;
          }
          .report-detail-wrapper_header_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          .report-detail-wrapper_desc_text {
            color: #5f6368;
            font-size: 12px;
          }
          .report-detail-wrapper_status_text {
            color: #3c4043;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
          }
        }
      }
    }
  }
  .report-detail-wrapper_right {
    flex-basis: 40%;
    // background-color: #f8f8fd;
    background-color: white;
    overflow: auto;
    // max-height: calc(100vh - 120px);
    height: calc(100vh - 96px);

    .report-detail_headertitle {
      line-height: 36px;
      font-size: 32px;
      color: #202124;
    }

    .report-detail_sub-headertitle {
      line-height: 20px;
      font-size: 28px;
      color: #444746;
    }

    .report-detail-wrapper_report_documentsection {
      min-height: 320px;
      margin: 3%;

      .image_container {
        margin-right: 10px;
        .image_container_body {
          height: 184px;
          width: 206px;
          border: 1px solid #dadce0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .image_containertext_body {
          height: 52px;
          width: 206px;
          border-top: none !important;
          border: 1px solid #dadce0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}

.header_button_container {
  height: 50px;
  border-bottom: 1px solid #dadce0;
  background-color: white;
}
.doc_border {
  width: 100px;
  border: 1px solid #dadce0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  .doc {
    height: 100%;
    width: 100%;
  }
  .static_image {
    width: 50px;
    height: 50px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the color and transparency as needed */
  color: white; /* Text color */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease;
}

.doc_border:hover .overlay {
  opacity: 1;
}

.report-detail-wrapper_container {
  .report-detail-wrapper_left {
    height: calc(100vh - 70px - 52px);
    overflow-y: auto;
    .report-detail-wrapper_left_container {
      margin-top: 0;

      .report-detail-wrapper_left_container_header_section {
        padding: 24px 26px;
        height: auto;
        border-bottom: 0;

        .header_text {
          font-size: $font-size-20;
          line-height: $line-height-25;
        }

        .desc_text {
          margin-top: 2px;
          font-size: $font-size-16;
          line-height: $line-height-20;
        }

        .small_text__12 {
          margin-top: 5px;
        }
      }
      .report_details_navigation_wrapper {
        padding-top: 28px;

        .inner-page-navigation-with-back-cta-buttons {
          height: 36px;
          padding: 0;
          margin: 0 26px;

          .inner-page-navigation-with-back-cta-buttons__navigation_left_temp {
            bottom: -0.5px;
            top: 0;
            left: 0;

            .inner-page-navigation-with-back-cta-buttons__nav-list-item {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }

      .reports-details-under-navtab-header {
        height: auto;
        padding: 16.5px 0 16px;
        margin: 0 24px;
        border-bottom: 0;

        .text_button_container {
          padding: 8px 16px;
          height: 40px;
          margin-left: 8px;
          font-weight: $medium-font-weight;
          border-radius: 4px;
          p {
            font-size: $font-size-16;
            line-height: $line-height-20;
            margin-left: 8px;
          }
        }
      }

      .report-detail-wrapper_left_container_list_section {
        padding: 0;
        max-height: 100%;
        height: auto;

        // &.mt-16 {
        //   margin-top: 16px;
        // }

        .report-detail-wrapper_left_container_list_item {
          // padding: 32px;
          margin-top: 0;
          box-shadow: none;

          .report-detail-wrapper-left-list-holder {
            .expense-detail-wrapper_report_infosection {
              .expense-detail-wrapper_report {
                margin: 0 16.5px;
                position: relative;

                &:first-child {
                  margin-left: 0;
                }

                &:last-child {
                  margin-right: 0;

                  &:after {
                    display: none;
                  }
                }

                &:after {
                  content: "";
                  height: 32px;
                  width: 1px;
                  background-color: #dadce0;
                  position: absolute;
                  right: -17px;
                  top: 50%;
                  transform: translateY(-50%);
                }

                .header_text {
                  font-size: $font-size-14;
                  line-height: $line-height-20;
                  margin-bottom: 3px;
                }

                .desc_text {
                  margin-top: 0;
                  font-size: $font-size-12;
                  line-height: $line-height-16;
                }

                //do next css
              }
            }
          }

          .report-detail-list-action-icons-wrapper {
            .report-detail-list-action-icons-holder {
              margin: 0 8px;
            }

            .action-icons-main-holder {
              margin: 0 8px;
              button {
                width: 24px;
                padding: 0;
              }

              .action-icon-wrap {
                height: 24px;
                width: 24px;
                transform: rotate(90deg);
              }

              .dropdown-menu {
                padding: 16px 0;
                border-radius: 0;
                border: 0;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                .dropdown-item {
                  padding: 10px 16px;
                  width: 100%;
                  color: $theme-font-heading-color;
                  font-size: $font-size-14;
                  line-height: $line-height-18;

                  &:hover {
                    background-color: #f7f7f7;
                    color: $theme-font-heading-color;
                  }
                }
              }
            }
          }

          .expense-details-card-desc-wrap {
            margin-bottom: 16px;
            .expense-details-card-desc {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}

.reports-details-dashed-border {
  margin: 16.5px auto;
  background-image: linear-gradient(
    to right,
    $theme-border-color 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 10px 3px;
  background-repeat: repeat-x;
  height: 1px;
  border: 0;
}

.admin-report-detail-wrapper_container {
  .report-detail-wrapper_left_container_list_section {
    &.mt-16 {
      margin-top: 16px;
    }
  }
}

.report-detail-wrapper_container {
  .report-detail-wrapper_right {
    .right_detail_container_wrap {
      // padding: 23.5px 40px;
      height: calc(100vh - 114px);

      .right-detail-top-wrapper {
        background: #f4ede5;
        padding: 32px;
      }

      .right-detail-bottom-wrapper {
        padding: 32px;
      }

      .reports-right-label-value-holder {
        .desc_text {
          font-size: $font-size-14;
          line-height: $line-height-18;
          font-weight: $medium-font-weight;
          color: $theme-font-primary-color;
          margin-bottom: 8px;
          margin-top: 0;
        }

        .header_text {
          font-size: $font-size-16;
          line-height: $line-height-18;
          font-weight: $normal-font-weight;
          color: $theme-font-heading-color;
        }
      }

      .report-right-details-border-bottom-spacing {
        margin: 16.5px 0;
      }

      .right_detail_container_created_by {
        font-size: $font-size-14;
        line-height: $line-height-18;
        font-weight: $medium-font-weight;
        color: $theme-font-primary-color;
        margin-bottom: 8px;
      }

      .view-approver-flow-wrap-reports {
        margin-top: 8.5px;
      }

      .right_detail_container_profile-wrap {
        .header_text {
          line-height: $line-height-18;
          font-size: $font-size-16;
          color: $theme-font-heading-color;
        }

        .profile-wrap-create,
        .profile-wrap-email {
          line-height: $line-height-14;
          margin-top: 3px;
          color: $theme-font-primary-color;
          font-size: $font-size-12;
        }
      }
    }
  }
}

.report_right_documents_upload {
  .report_right_documents_upload_label {
    font-size: $font-size-14;
    line-height: $line-height-18;
    font-weight: $medium-font-weight;
    color: $theme-font-primary-color;
  }

  .report_right_documents_addFiles_label {
    font-size: $font-size-14;
    line-height: $line-height-18;
    font-weight: $medium-font-weight;
    color: #276ef1;
    margin-left: 8px;
  }
}

.report-with-trips {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    background-color: $theme-border-color;
    left: -15px;
    top: 0;
  }
}

.reports-accordion {
  .accordion-item {
    // border: none !important;
  }

  .accordion-header {
    // border-bottom: 1px solid #eee;
  }

  .accordion-button:not(.collapsed) {
    background: none !important;
  }

  .header_text {
    font-weight: 500;
  }

  // .accordion-body {
  //   padding: 0 !important;
  // }

  .accordion-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-radius: 8px;
    padding: 24px !important;
    // border: 1px solid #dadce0;

    &::after {
      display: none;
    }
  }

  .expense-accordion-items-detail {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }

  .accordion-body {
    margin-bottom: 1rem;
    border-bottom: 0 0 8px 8px;
    padding: 24px !important;
  }

  .accordion-item {
    border-radius: 8px;
    margin-top: 1rem;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .accordion-item:first-of-type > .accordion-header .accordion-button {
    border-radius: 8px !important;
  }

  .report-accordion-icon {
    border: 1px solid #dadce0;
    border-radius: 8px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #f9eeff;
      border: 1px solid #fff;
    }
  }
}
