@import "@scss/common/variables";
.main-wrapper {
  // // margin-top: 65px;
  // margin-top: 64px;
  // width: 100%;
  // display: flex;
  // justify-content: flex-start;
  // // height: calc(100vh - 65px);
  // height: calc(100vh - 64px);

  padding-top: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: 100vh;
  overflow-x: hidden;

  .db_header_scroll {
    &::before {
      position: absolute;
      box-sizing: border-box;
      bottom: -4px;
      box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        inset 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      width: 100%;
      content: "";
      height: 4px;
      left: 0px;
    }
  }
  .main-user-wrapper {
    max-height: calc(100vh - 65px);
    height: calc(96vh - 65px);
    overflow-y: auto;
    border-right: 1px solid $theme-border-color;
  }
  .dashboard-wrapper {
    flex-direction: column;
    max-width: 100%;
    // max-width: calc(100% - 170px);
    // max-width: calc(100% - 185px);
    width: 100%;
    // overflow-x: auto;
    overflow: hidden;

    .dashboard-wrapper__header {
      padding: 0 24px;
      height: 60px;
      // box-shadow: 0px 3px 6px #00000029;
      // background-color: #f8f9fa;
      background-color: #fff;
      // background-color: $white-color;
      z-index: 99;
      position: sticky;
      top: 0;
      // border-bottom: 1px solid #dadce0;
      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dashboard-wrapper__header-title {
        color: #3c4043;
        font-size: 20px;
        font-weight: 400;
      }
    }
    .footer-container {
      position: absolute;
      background-color: rgba(var(--primary-color), 0.1);
      // background-color: #fdfeff;
      // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      // box-shadow: 0px 0px 6px 6px #00000029;
      color: rgba(var(--primary-color), 1);
      padding: 11px;
      bottom: 0;
      left: 0;
      width: 169px;
      border-right: 1px solid $theme-border-color;

      z-index: 1;
      p {
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

.main-wrapper--no-navigation-menu {
  // margin-top: 65px;
  padding-top: 64px;
}
.main-wrapper__content {
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  overflow-y: auto;
  position: relative;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer-wrapper--task {
  position: absolute;
  left: 0;
  right: 0;
}
.dashboard-wrapper__dropdown {
  color: $theme-font-heading-color;
  font-weight: $medium-font-weight;
  margin: 0 10px;
  font-size: 18px;
}
.dashboard-wrapper__apply-btn {
  border: 1px solid $theme-border-color;
  background-color: $white-color;
  padding: 0px 16px;
  height: 34px;
  line-height: 34px;
  margin-left: 12px;
  border-radius: $base-border-radius;
  color: #3c4043;
  transition: 0.3s all ease-in-out;
  &:hover {
    color: $theme-secondary-color;
    border-color: $theme-secondary-color;
  }
}
.dropdown-toggle::after {
  content: none;
}

.dashboard-compliance__date {
  z-index: 2;
  background-color: $white-color;
  font-weight: 400;
  font-size: 16px;
  margin: 0 8px 8px 0;
}
.dashboard-compliance__date--compliance {
  margin: 0;
  padding-left: 8px;
}
.dashboard-compliance__title {
  font-size: 20px;
  color: $theme-font-heading-color;
  font-weight: 500;
  margin: 0 8px 8px 0;
  position: relative;
  z-index: 2;
  padding-right: 8px;
  background-color: $white-color;
}
