.custom-radio {
  display: none;
}

.gray-color {
  color: #797979 !important;
}

.radio-container {
  margin-bottom: 10px;
  position: relative;
}
.ribbon {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: 0.5em;

  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((1 - cos(45deg)) * 100%), -100%) rotate(45deg);
  transform-origin: 0% 100%;
  background-color: #bd1550;
}

.dot-seprator {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #5f6368;
}

.custom-radio + label {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.1s ease-in;
  background: #fff;
  border: 3px solid #cfe6f7;
}

.custom-radio:checked + label {
  border-color: #893297;
}

.trip-radio {
  display: none;
}

.trip-radio + label {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
  transition: all 0.1s ease-in;
  background: #fff;
  border: 2px solid #dadce0;
  white-space: nowrap;
  padding: 6px 16px;
}

.trip-radio:checked + label {
  background: #e8f0fd;
  border: 2px solid #e8f0fd;
}

.trip-selector {
}

.online-booking-wrapper {
  border-bottom: 1px solid #dadce0;
}

.grayed-border-top {
  border-top: 1px solid #dadce0;
}

.option-selector {
  width: 95px;
  height: 95px;
}

.right-side-image {
  position: absolute;
  top: 0;
  right: 0;
}

.left-side-image {
  position: absolute;
  top: 0;
  left: 0;
}

.arrows-container {
  height: 36px;
  width: 36px;
  background: #edeff1;
  border-radius: 50%;
  padding: 6px;
}

.search-input {
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  height: 48px;
  max-width: 320px;
  width: 100%;
  padding: 0 16px 0 44px;

  &::placeholder {
    color: #9d9d9d;
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.calender-border-left {
  border-right: 1px solid #dadce0;
  padding-right: 16px;
}

.calender-wrapper {
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  cursor: pointer;

  span {
    white-space: nowrap;
  }

  .grayed-text {
    color: #9d9d9d;
  }
}

.theme-destination-card {
  width: 100%;

  .destination-images {
    background: #eaeaea;
    height: 144px;
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
  }
}

.CustomerCard-slide-left,
.CustomerCard-slide-right,
.theme-slide-left,
.theme-slide-right {
  background: none;
  outline: none;
  border: none;
}

.cab-options {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  border-radius: 20px;
  padding: 0 16px;
  border: 1px solid #dadce0;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
}

.cab-selected {
  background: #e8f0fd;
  border-color: #e8f0fd;
}

.property-filter-wrapper {
  width: 400px;
}

.filtered-property {
  border: 1px solid #dadce0;
  border-radius: 8px;
}

.original-price {
  text-decoration: line-through;
  color: #616161;
}

.checkbox-width {
  width: 20px;
}
