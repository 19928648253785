.property-nav {
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  box-shadow: 0px 3px 6px #00000029;
}

.travel-offers {
  border-top: 1px solid #dadce0;
}

.border-bottom{
  border-bottom: 1px solid #dadce0;
}

.popular-amenities {
  border-top: 1px solid #dadce0;
}

.property-thumbnail{
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.time-progress-bar {
  background: #dadce0;
  width: 160px;
  height: 4px;

  @media screen and (min-width: 1100px) {
    width: 260px;
  }

  .current-progress {
    background: #ee3869;
    width: 40px;
    height: 4px;

    @media screen and (min-width: 1100px) {
      width: 80px;
    }
  }
}

.teritiary-color{
  color: #ee3869!important;
}

.custom-tick {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 24px;

  .ticked-list {
    position: relative;
    color: #5f6368;
    padding: 6px 0;
    &::before {
      content: "";
      position: absolute;
      left: -22px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-image: url("../../../../../public/images/dashboard/trips/online/check.svg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 8px;
    }
  }
}

.summary-nav{
  height: 50px;
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 9;
}

.selected-summary{
  height: 80px;
  width: 100%;
  position: fixed;
  background: #fff;
  margin-top: 50px;
  z-index: 9;
}

.summary-details{
  height: calc(100vh - 130px);
  margin-top: 130px;
  margin-bottom: 100px;
}